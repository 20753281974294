// src/contexts/AccessibilityContext.js
import React, { createContext, useState, useContext } from 'react';

const AccessibilityContext = createContext();

export const AccessibilityProvider = ({ children }) => {
  const [selectedMode, setSelectedMode] = useState(null);

  return (
    <AccessibilityContext.Provider value={{ selectedMode, setSelectedMode }}>
      {children}
    </AccessibilityContext.Provider>
  );
};

export const useAccessibility = () => useContext(AccessibilityContext);
