import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import config from '../config';
import { useNavigate } from "react-router";
import { Link } from "react-router";

const CourseList = ({ forUser }) => {
  const { user } = useContext(UserContext);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      let url = `${config.apiBaseUrl}/courses`;
      const token = localStorage.getItem('token');
      if (forUser) {
        if (!user || !token) {
          navigate('/connexion'); // Redirect if user is not logged in
          return;
        }
        url += `?id_formateur=${user.id_utilisateur}`;
      }

      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setCourses(data);
        } else {
          throw new Error('Failed to fetch courses');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [forUser, user, navigate]);

  if (loading) return <div className="text-center mt-5"><div className="spinner-border text-primary" role="status"><span className="visually-hidden">Chargement...</span></div></div>;
  if (error) return <div className="alert alert-danger" role="alert">Error: {error}</div>;

  return (
    <div className="container my-5">
      <h2 className="mb-4">{forUser ? "Mes Cours" : "Tous les Cours"}</h2>
      {courses.length === 0 ? (
        <div className="alert alert-info" role="alert">Aucun cours.</div>
      ) : (
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Titre</th>
                <th scope="col">Matière</th>
                <th scope="col">Niveau</th>
              </tr>
            </thead>
            <tbody>
              {courses.map(course => (
                <tr key={course.id_cours}>
                  <td><Link to={`/cours/${course.id_cours}`}>{course.titre}</Link></td>
                  <td>{course.matiere}</td>
                  <td>{course.niveau}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {forUser && (
        <Link to="/creer-cours" className="btn btn-primary mb-3">Créer un nouveau cours</Link>
      )}
    </div>
  );
};

export default CourseList;
