import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate, useParams } from 'react-router';
import { TextField, Button } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react'; // Import TinyMCE editor
import config from '../config';
import DOMPurify from 'dompurify';
import usePrompt from '../hooks/usePrompt';
import { useAccessibility } from '../contexts/AccessibilityContext';

function sanitizeContent(htmlContent) {
  return DOMPurify.sanitize(htmlContent);
}


const CreateLesson = () => {
  const { user } = useContext(UserContext);
  const { id_cours, id_lesson } = useParams(); // Get lesson ID if modifying
  const [titre, setTitre] = useState('');
  const [contenu, setContenu] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [initialTitre, setInitialTitre] = useState('');
  const [initialContenu, setInitialContenu] = useState('');
  const token = localStorage.getItem('token');
  const { selectedMode } = useAccessibility();
  //const isModified = useRef(false);

  usePrompt(
    'Vous avez des modifications non enregistrées. Voulez-vous vraiment quitter ?',
    titre !== initialTitre || contenu !== initialContenu
  );


  useEffect(() => {
    document.title = id_lesson ? "Modifier la leçon" : "Créer une leçon";

    if (!user || !token) {
      navigate('/connexion');
      return;
    }

    // **Validation: Check user permissions**
    if (!id_lesson) {
      // Creating a lesson: User must be a formateur or admin
      if (user.type !== 'admin' && user.type !== 'formateur') {
        setError("Vous n'avez pas la permission de créer une leçon.");
        return;
      }
    } else {
      // Modifying a lesson: User must be the creator or an admin
      fetch(`${config.apiBaseUrl}/lessons/${id_lesson}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then((response) => {
          if (!response.ok) throw new Error('Échec du chargement de la leçon');
          return response.json();
        })
        .then((data) => {
          setTitre(data.titre);
          setContenu(data.contenu);
          setInitialTitre(data.titre);
          setInitialContenu(data.contenu);

          if (user.id_utilisateur !== data.id_formateur && user.type !== 'admin') {
            setError("Vous n'avez pas la permission de modifier cette leçon.");
          }
        })
        .catch((error) => setError(error.message));
    }
  }, [user, token, navigate, id_lesson]);

  // Warn before leaving the page (browser close, refresh, etc.)
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (titre !== initialTitre || contenu !== initialContenu) {
        event.preventDefault();
        event.returnValue = "Vous avez des modifications non enregistrées. Voulez-vous vraiment quitter ?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Intercept navigation within the app
  // Use the custom hook to warn about unsaved changes
  //useUnsavedChangesWarning(isModified.current);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!titre.trim()) return alert('Le titre ne doit pas être vide.');
    if (!contenu.trim()) return alert('Le contenu ne doit pas être vide.');
    const sanitizedContent = sanitizeContent(contenu)
    try {
      const method = id_lesson ? 'PATCH' : 'POST';
      const url = id_lesson
        ? `${config.apiBaseUrl}/lessons/${id_lesson}`
        : `${config.apiBaseUrl}/lessons/${id_cours}`;

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ titre, contenu: sanitizedContent })
      });
      const json_response = await response.json();
      if (!response.ok) {

        throw new Error(json_response.message || 'Erreur lors de la sauvegarde de la leçon');
      }

      alert(id_lesson ? 'La leçon a été mise à jour avec succès.' : 'La leçon a été ajoutée avec succès.');
      navigate(`/cours/${id_cours}/lecon/${id_lesson ? id_lesson : json_response.id_lesson}`);
    } catch (error) {
      setError(error.message);
    }
  };

  // Mark form as modified
  const handleTitleChange = (e) => {
    console.log("title change")
    setTitre(e.target.value);
  };

  const handleEditorChange = (content) => {
    console.log("text change")
    setContenu(content);
  };

  const isDarkMode = selectedMode === 'malvoyant';
  console.log(isDarkMode)

  return (
    <div className="container my-5">
      <h1 className="mb-4">{id_lesson ? "Modifier une leçon" : "Créer une nouvelle leçon"}</h1>
      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      {/* Prompt before navigation */}

      <form onSubmit={handleSubmit}>
        <TextField
          label="Titre"
          type="text"
          value={titre}
          onChange={handleTitleChange}
          fullWidth
          required
        />
        <Editor
          apiKey="c44p1hrf4yc4rvjxbrx3g5tigo8g62fxhng18sfccvldbwvr" // Replace with your TinyMCE API key
          value={contenu}
          onEditorChange={handleEditorChange}
          init={{
            height: 400,
            menubar: 'edit insert view format table tools help',
            language: 'fr_FR',
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | link image | a11ycheck | help',
            block_formats: 'Paragraphe=p; Titre 2=h2; Titre 3=h3; Titre 4=h4; Titre 5=h5; Titre 6=h6;',
            style_formats: [
              {
                title: 'Titres', items: [
                  { title: 'Titre 2', format: 'h2' },
                  { title: 'Titre 3', format: 'h3' },
                  { title: 'Titre 4', format: 'h4' },
                  { title: 'Titre 5', format: 'h5' },
                  { title: 'Titre 6', format: 'h6' }
                ]
              },
              {
                title: 'Inline', items: [
                  { title: 'Gras', format: 'bold' },
                  { title: 'Italique', format: 'italic' },
                  { title: 'Souligné', format: 'underline' },
                  { title: 'Barré', format: 'strikethrough' },
                  { title: 'Exposant', format: 'superscript' },
                  { title: 'Indice', format: 'subscript' },
                  { title: 'Code', format: 'code' }
                ]
              },
              {
                title: 'Blocs', items: [
                  { title: 'Paragraphe', format: 'p' },
                  { title: 'Citation', format: 'blockquote' },
                  { title: 'Div', format: 'div' },
                  { title: 'Préformaté', format: 'pre' }
                ]
              },
              {
                title: 'Alignement', items: [
                  { title: 'Aligner à gauche', format: 'alignleft' },
                  { title: 'Centrer', format: 'aligncenter' },
                  { title: 'Aligner à droite', format: 'alignright' },
                  { title: 'Justifier', format: 'alignjustify' }
                ]
              }
            ],

            skin: isDarkMode ? 'oxide-dark' : 'oxide',
            content_css: isDarkMode ? 'dark' : 'default',

          }}

        />
        <Button type="submit" variant="contained" color="primary" className="mt-3">
          {id_lesson ? "Modifier" : "Créer"}
        </Button>
      </form>
    </div>
  );
};

export default CreateLesson;
