import React, { useEffect } from 'react';

const solutions = [
    {
        name: 'AVA',
        utility: 'La solution de transcription pour une autonomie totale',
        url: 'https://fr.ava.me/'
    },
    {
        name: 'LeDicoElix',
        utility: 'Elix est un dictionnaire bilingue français / Langue des Signes Française (LSF) qui fonctionne comme un moteur de recherche : il vous suffit de saisir un mot dans le DicoElix pour qu\'il vous propose, en vidéo, le signe associé et sa définition en LSF.',
        url: 'https://dico.elix-lsf.fr/'
    },
];

const AccessibilitySolutions = () => {
    useEffect(() => {
        document.title = "Catalogue de solutions d'accessibilité pour les sourds et malentendants";
    }, []);

    return (
        <div className="container mt-5">
            <h1 className="mb-4 text-center">Catalogue de solutions d'accessibilité pour les sourds et malentendants</h1>
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead className="table-dark">
                        <tr>
                            <th scope='col'>Nom</th>
                            <th scope='col'>Utilité</th>
                            <th scope='col'>Adresse</th>
                        </tr>
                    </thead>
                    <tbody>
                        {solutions.map((solution, index) => (
                            <tr key={index}>
                                <td className="fw-bold">{solution.name}</td>
                                <td>{solution.utility}</td>
                                <td>
                                    <a href={solution.url} target="_blank" rel="noopener noreferrer">
                                        {solution.url}
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AccessibilitySolutions;