// src/components/Layout.js
import React from 'react';
import NavMenu from './NavMenu';
import AccessibilityMenu from './AccessibilityMenu';
import Footer from './Footer';
import { Outlet } from 'react-router';

const Layout = () => {
  return (
    <div className="layout">
      <AccessibilityMenu />
      <NavMenu />
      <main className="main-content"><Outlet /></main>
      <Footer />
    </div>
  );
};

export default Layout;