import React, { useState } from 'react';

const expectedResponses = {
    nom: "FJNQTD",
    prenom: "DTQNJF (espace) GH",
    surnom: "ESANTIRULOC",
    fruits: ["banane", "pomme"],
    genre: "masculin",
    classe: "CM2",
    commentaire: "Je fais des cours avec Oubii Digital pour savoir utiliser mon ordi en tant que personne aveugle."
};

const formatInput = (input) => {
    return input.trim().toUpperCase().replace(/\s+/g, '');
};

const compareResponses = (expected, actual) => {
    const formattedActual = actual.trim().toUpperCase();
    let formattedExpected = expected.split('').join(' ');
    let formattedUser = formattedActual.replace(/ /g, ' (espace) ');
    formattedUser = formattedUser.split('').join(' ');
    formattedUser = formattedUser.replace("( e s p a c e )", '(espace)');
    formattedExpected = formattedExpected.replace("( e s p a c e )", '(espace)');
    return formattedActual === expected ? null : {
        expected: formattedExpected,
        user: formattedUser
    };
};

const WebComponentsExercice = () => {
    const [responses, setResponses] = useState({ nom: "", prenom: "", surnom: "", fruits: [], genre: "", classe: "", commentaire: "" });
    const [validationResults, setValidationResults] = useState(null);


    const handleChange = (e) => {
        const { id, type, value, checked } = e.target;
        if (type === "checkbox") {
            setResponses((prev) => ({
                ...prev,
                fruits: checked ? [...prev.fruits, value] : prev.fruits.filter(fruit => fruit !== value)
            }));
        } else if (type === "radio") {
            setResponses((prev) => ({ ...prev, genre: value }));
        }

        else {
            setResponses((prev) => ({ ...prev, [id]: value }));
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("responses", responses)

        let results = {};
        Object.keys(expectedResponses).forEach((field) => {
            if (Array.isArray(expectedResponses[field])) {
                const correct = expectedResponses[field].every(fruit => responses.fruits.includes(fruit)) && responses.fruits.length === expectedResponses[field].length;

                results[field] = {
                    expected: expectedResponses[field].join(', '),
                    user: responses.fruits.join(', ') || "Aucun sélectionné",
                    correct
                };

            } else if (field === "commentaire") {
                const formattedUserComment = responses.commentaire.trim().toLowerCase().replace(/[.\s]+$/, "");
                const formattedExpectedComment = expectedResponses.commentaire.toLowerCase().replace(/[.\s]+$/, "");

                results[field] = {
                    expected: expectedResponses.commentaire,
                    user: responses.commentaire,
                    correct: formattedUserComment === formattedExpectedComment
                };

            

            } else {
                console.log("field", field)
                const comparison = compareResponses(expectedResponses[field], responses[field]);
                if (comparison) {
                    results[field] = comparison;
                }
            }
        });
        console.log("results", results)
        setValidationResults(results);
    };

    return (
        <div>
            <p>Complétez ce formulaire en suivant précisément les instructions.</p>
            <form onSubmit={handleSubmit}>
                <h3>Nom</h3>
                <label htmlFor="nom">Dans le champ nom taper : F J N Q T D (sans espace entre les lettres sauf indication contraire)</label>
                <input id="nom" type="text" value={responses.nom} onChange={handleChange} />

                <h3>Prénom</h3>
                <label htmlFor="prenom">Dans le champ prénom taper : D T Q N J F (espace) G H</label>
                <input id="prenom" type="text" value={responses.prenom} onChange={handleChange} />

                <h3>Surnom</h3>
                <label htmlFor="surnom">Dans le champ surnom taper : E S A N T I R U L O C</label>
                <input id="surnom" type="text" value={responses.surnom} onChange={handleChange} />

                <h3>Les fruits que vous aimez</h3>
                <fieldset>
                    <legend>Les fruits que vous aimez cochez (banane et pomme) :</legend>
                    {['pastèque', 'citron', 'banane', 'mandarine', 'pomme', 'abricot'].map(fruit => (
                        <label key={fruit}>
                            <input type="checkbox" value={fruit} checked={responses.fruits.includes(fruit)} onChange={handleChange} /> {fruit.charAt(0).toUpperCase() + fruit.slice(1)}
                        </label>
                    ))}
                </fieldset>

                <h3>Genre</h3>
                <fieldset>
                    <legend>Sélectionnez masculin pour votre genre :</legend>
                    <label><input type="radio" name="genre" value="masculin" onChange={handleChange} /> Masculin</label>
                    <label><input type="radio" name="genre" value="féminin" onChange={handleChange} /> Féminin</label>
                </fieldset>

                <h3>Classe</h3>
                <label htmlFor="classe">Sélectionnez CM2 pour votre classe :</label>
                <select id="classe" onChange={handleChange} >
                    <option>CE2</option>
                    <option>CM1</option>
                    <option>CM2</option>
                    <option>6è</option>
                    <option>5è</option>
                    <option>4è</option>
                </select>

                <h3>Commentaire</h3>
                <label htmlFor="commentaire">Champ commentaire taper "Je fais des cours avec Oubii Digital pour savoir utiliser mon ordi en tant que personne aveugle." (Oubii s'épelle "O U B I I". Le O de Oubii et le D de Digital doivent être des majuscules
                    )  :</label>
                <textarea id="commentaire" aria-label="Commentaire" onChange={handleChange}></textarea>

                <button type="submit">Valider</button>
            </form>

            {validationResults && (
                <div role='alert' aria-live='polite'>
                    <h3>Résultats</h3>
                    {Object.entries(validationResults).map(([field, result]) => (
                        <div key={field}>
                            <p><strong>{field.charAt(0).toUpperCase() + field.slice(1)} :</strong></p>
                            <p>Réponse {result.correct || result.expected === result.user  ? "correcte" : "incorrecte"}</p>
                            <p>Réponse attendue: {result.expected}</p>
                            {result.user && <p>Votre réponse: {result.user}</p>}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default WebComponentsExercice;
