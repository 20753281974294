// src/components/SignUp.js
import React, { useState, useEffect, useContext } from 'react';
import { TextField, RadioGroup, Radio, FormControlLabel, Button, FormControl, FormLabel } from '@mui/material';
import { useNavigate } from "react-router";
import { UserContext } from '../contexts/UserContext';
import config from '../config'; // Import the configuration

const SignUp = () => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [mot_de_passe, setMotDePasse] = useState('');
  const [confirme_mot_de_passe, setConfirmeMotDePasse] = useState('');
  const [type, setType] = useState('');
  const [errorText, setErrorText] = useState('');
  const [confirmErrorText, setConfirmErrorText] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  useEffect(() => {
    document.title = "Inscription"; // Change the document title when the component mounts
  }, []);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setMotDePasse(value);

    if (value.length < 8) {
      setErrorText('Le mot de passe doit contenir au moins 8 caractères.');
    } else if (value.trim() !== value) {
      setErrorText('Le mot de passe ne doit pas contenir d\'espace avant ou après.');
    } else {
      setErrorText('');
    }

    if (confirme_mot_de_passe && value !== confirme_mot_de_passe) {
      setConfirmErrorText('Les mots de passe ne correspondent pas.');
    } else {
      setConfirmErrorText('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmeMotDePasse(value);

    if (value !== mot_de_passe) {
      setConfirmErrorText('Les mots de passe ne correspondent pas.');
    } else {
      setConfirmErrorText('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!type) {
      return alert("Type is required");
    }

    if (mot_de_passe.length < 8 || mot_de_passe.trim() !== mot_de_passe) {
      return alert("Le mot de passe doit contenir au moins 8 caractères avec aucun espace avant ou après.");
    }

    if (mot_de_passe !== confirme_mot_de_passe) {
      return alert("Les mots de passe ne correspondent pas !");
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nom, prenom, email, mot_de_passe, type }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'An error occurred during signup';
        throw new Error(errorMessage);
      }

      const data = await response.json();
      login(data); // Update context and localStorage
      alert(`Inscription réussie, ${data.user.prenom}`);
      navigate('/');
    } catch (error) {
      alert(`Erreur: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Inscription</h1>
      <form className="FormControl" onSubmit={handleSubmit}>

        <TextField
          label="Prénom"
          type="text"
          value={prenom}
          onChange={(e) => setPrenom(e.target.value)}
          fullWidth
          required
        />

        <TextField
          label="Nom"
          type="text"
          value={nom}
          onChange={(e) => setNom(e.target.value)}
          fullWidth
          required
        />

        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Mot de passe"
          type="password"
          value={mot_de_passe}
          onChange={(e) => handlePasswordChange(e)}
          fullWidth
          required
          error={!!errorText}
          helperText={errorText || 'Le mot de passe doit contenir au moins 8 caractères avec aucun espace avant ou après.'}
        />
        <TextField
          label="Confirmer le mot de passe"
          type="password"
          value={confirme_mot_de_passe}
          onChange={(e) => handleConfirmPasswordChange(e)}
          fullWidth
          required
          helperText={confirmErrorText}
          error={!!confirmErrorText}
        />

        <FormControl component="fieldset" fullWidth required>
          <FormLabel component="legend">Type</FormLabel>
          <RadioGroup
            aria-label="Type"
            name="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <FormControlLabel value="apprenant" control={<Radio />} label="Apprenant" />
            <FormControlLabel value="formateur" control={<Radio />} label="Formateur" />
          </RadioGroup>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">
          Envoyer
        </Button>
      </form>
    </div>
  );
};

export default SignUp;
