import { useCallback, useEffect } from 'react';
import { useBlocker as useBlocker } from 'react-router';

function usePrompt(message, when = true) {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const proceed = window.confirm(message);
      if (proceed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker, message]);
}

export default usePrompt;
