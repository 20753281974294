import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import config from '../config';
import { useNavigate, useParams, Link } from "react-router";

function CourseDetails() {
  const { user } = useContext(UserContext);
  const { id_cours } = useParams(); // id du cours
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [userInfo, setUserInfo] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Détails du cours";

    const fetchCourseDetails = async () => {



      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${config.apiBaseUrl}/courses/${id_cours}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des infos du cours.');
        }
        const data = await response.json();
        setCourse(data);

        if (course?.titre) {
          document.title = course.titre
        }

        setLessons(data.lessons || []);
        // Récupérer les utilisateurs
        const userResponse = await fetch(`${config.apiBaseUrl}/users/${data.id_formateur}`);
        if (!userResponse.ok) {
          throw new error('Erreur lors de la récupération des infos du formateur.');
        }
        const userdata = await userResponse.json();
        setUserInfo(userdata);
        // récupérer les leçons
        const lessonResponse = await fetch(`${config.apiBaseUrl}/lessons?id_cours=${data.id_cours}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!lessonResponse.ok) {
          throw new error('Failed to fetch lessons')
        }
        const lessonData = await lessonResponse.json();
        setLessons(lessonData);


      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, []);

  if (loading) return <div className="text-center mt-5"><div className="spinner-border text-primary" role="status"><span className="visually-hidden">Chargement...</span></div></div>;
  if (error) return <div className="alert alert-danger" role="alert">Error: {error}</div>;

  const date_creation = course?.date_creation ? new Date(course.date_creation) : null;
  const date_modification = course?.date_modification ? new Date(course.date_modification) : null;
  console.log("userinfo",userInfo)
  return (
    <div className="container my-5">
      <h1 className="mb-4">{course?.titre}</h1>
      {course?.matiere &&
        <p>Matière : {course?.matiere}</p>
      }
      {course?.description &&
        <p>Description : {course?.description}</p>
      }
      {course?.niveau &&
        <p>Niveau : {course?.niveau}</p>
      }
      {course?.date_creation &&
        <p>Date de création : {date_creation.toLocaleDateString()} {date_creation.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
      }
      {course?.date_modification &&
        <p>Date de modification : {date_modification.toLocaleDateString()} {date_modification.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
      }
      {course?.date_duree &&
        <p>Durée du cours : {course?.date_duree}</p>
      }
      {course?.id_categorie &&
        <p>Id_categorie : {course?.id_categorie}</p>
      }
      <h2>Formateur</h2>

      {userInfo ? (
        <div>
          <p>{userInfo.prenom} {userInfo.nom}</p>
          <p>{userInfo.email}</p>
        </div>
      ) : (
        <p>Aucune information utilisateur disponible.</p>
      )}





      <h2>Leçons</h2>
      {lessons.length > 0 ? (
        <ul>
          {lessons.map(lesson => (
            <li key={lesson.id_lesson}>
               <Link to={`/cours/${id_cours}/lecon/${lesson.id_lesson}`}>
                {lesson.titre}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>Aucune leçon disponible pour ce cours.</p>
      )}




      {user && (userInfo.id_utilisateur === user.id_utilisateur|| user.type === 'admin') && (
        <Link to={`/cours/${id_cours}/ajouterlecon`} className="btn btn-primary mb-3">
          Ajouter une leçon au cours
        </Link>
      )}
    </div>
  );
}


export default CourseDetails;
