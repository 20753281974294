import React, { useEffect, useState } from 'react';
import WebComponentsExercice from '../exercices/WebComponentsExercice';

const WebComponentsLesson = () => {
    useEffect(() => {
        document.title = "Comprendre les composants web";
    }, []);

    return (
        <div>
            <h1>Comprendre les composants web</h1>
            
            <h2>Théorie</h2>
            <h3>Les Liens</h3>
            <p>Un lien est un élément cliquable qui permet de naviguer vers une autre page ou une section de la page.</p>
            <a href="https://oubii.digital/">Visitez notre site</a>

            <h3>Les Boutons</h3>
            <p>Un bouton est un élément interactif qui déclenche une action.</p>
            <button onClick={() => alert('Bouton cliqué !')}>Cliquez-moi</button>

            <h3>Les Cases à Cocher (Checkbox)</h3>
            <p>Une case à cocher permet de sélectionner une ou plusieurs options.</p>
            <p>Exemple:</p>
            <label>
                <input type="checkbox" /> Accepter les termes
            </label>

            <h3>Les Boutons Radio</h3>
            <p>Les boutons radio permettent de choisir une seule option dans un groupe.</p>
            <p>Exemple:</p>
            <label>
                <input type="radio" name="choix" /> Option 1
            </label>
            <label>
                <input type="radio" name="choix" /> Option 2
            </label>

            <h3>Les Listes Déroulantes</h3>
            <p>Une liste déroulante permet de choisir une option parmi plusieurs.</p>
            <p>Exemple:</p>
            <label htmlFor="select-menu">Choisissez une option :</label>
            <select id="select-menu">
                <option>Option 1</option>
                <option>Option 2</option>
                <option>Option 3</option>
            </select>

           

            <h3>Les Champs de Texte</h3>
            <p>Un champ de texte permet à l'utilisateur de saisir des informations.</p>
            <p>Exemple:</p>
            <label htmlFor="text-input">Votre nom :</label>
            <input id="text-input" type="text" placeholder="Entrez votre nom" />

            <h3>Les Zones de Texte Multilignes</h3>
            <p>Une zone de texte multiligne permet de saisir plusieurs lignes de texte.</p>
            <p>Exemple:</p>
            <label htmlFor="textarea">Votre commentaire :</label>
            <textarea id="textarea" placeholder="Entrez votre commentaire"></textarea>
            <h2>Pratique</h2>
        
            <WebComponentsExercice/>
            
        </div>
    );
};

export default WebComponentsLesson;
