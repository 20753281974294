import React, { useEffect } from 'react';
import { useAccessibility } from '../contexts/AccessibilityContext';

const accessibilityModes = [
    { id: 'aveugle', label: 'Aveugle', subtext: 'Écouter le cours, navigation à la voix' },
    { id: 'malvoyant', label: 'Malvoyant', subtext: 'Polices dédiées, Dark mode' },
    { id: 'deaf', label: 'Sourds / Malentendants', subtext: 'Illustrations visuelles, langue des signes' },
   /* { id: 'custom', label: 'Plus de Réglages', subtext: 'Personnaliser davantage votre expérience' }*/
];

const AccessibilityMenu = () => {
    const { selectedMode, setSelectedMode } = useAccessibility();

    const settings = localStorage.getItem("settings");
    console.log("settings", settings)
    const parsedSettings = settings ? JSON.parse(settings) : null;
    const choix_profil = parsedSettings ? parsedSettings.choix_profil : null;

    console.log("choix profil", choix_profil)

    useEffect(() => {
        if(choix_profil ==="malvoyant"){
            setSelectedMode(choix_profil)
        }
    }, []);
    
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data?.type === "profileChange") {
                console.log("message received", event.data)
                /* const newMode = accessibilityModes.find(mode => mode.id === event.data.value)?.id || null;*/
                setSelectedMode(event.data.value);
            }
        };

        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, [setSelectedMode]);


    const handleButtonClick = (mode) => {
        console.log("button clicked", mode)
        if(mode.id === selectedMode){
            setSelectedMode(null);
            window.bcSetProfile("defaut",false)
        }else{
            setSelectedMode(mode.id);
            if (window.bcSetProfile) {
                window.bcSetProfile(mode.id === "malvoyant" ? mode.id : "defaut", false)
            }
        }
        


    };

    return (
        <nav aria-label="Modes d'accessibilité" id="accessibilityMenu" className="accessibility-menu">
            {accessibilityModes.map((mode) => {
                const isSelected =  selectedMode === mode.id;

                return (
                    <button
                        key={mode.id}
                        onClick={() => handleButtonClick(mode)}
                        aria-pressed={isSelected}
                        className={`accessibility-button ${isSelected ? "selected" : ""}`}
                    >
                        <span className="button-label">{mode.label}</span>
                        <br />
                        <span className="button-subtext">{mode.subtext}</span>
                    </button>
                );
            })}
            <button
                onClick={() => window.bcOpenClose()}
                className="accessibility-button"
            >
                <span className="button-label">Plus de Réglages</span>
                <br />
                <span className="button-subtext">Personnaliser davantage votre expérience</span>
            </button>
        </nav>
    );
};

export default AccessibilityMenu;