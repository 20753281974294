// src/components/CODLesson.js
import React, { useState, useEffect } from 'react';
import { Link } from "react-router";
import CODExercise from '../CODExercise';
import Form from '../Form';
import { Button} from '@mui/material';
import { useRef } from 'react';
import { useAccessibility } from '../../contexts/AccessibilityContext';

const CODLesson = () => {

    useEffect(() => {
        document.title = "C.O.D: Complément d'Objet Direct";
        return () => {
            synth.current.cancel(); // Stop speech synthesis on unmount
          };
    }, []);



    const [lessonUnderstood, setLessonUnderstood] = useState(false);
    const [attentionUnderstood, setAttentionUnderstood] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const synth = useRef(window.speechSynthesis);
    const utterance = useRef(null);
    const { selectedMode } = useAccessibility();

    console.log("selected Mode", selectedMode)

    const handleReadLesson = () => {
        const lessonDiv = document.getElementById("lesson")
        if (!lessonDiv.textContent) return;

        if (isSpeaking) {
            synth.current.pause();
            setIsSpeaking(false);
            setIsPaused(true);
        } else if (isPaused) {
            synth.current.resume();
            setIsSpeaking(true);
            setIsPaused(false);
        } else {
            utterance.current = new SpeechSynthesisUtterance(lessonDiv.textContent); // Remove HTML tags
            utterance.current.lang = 'fr-FR';
            utterance.current.onend = () => {
                setIsSpeaking(false);
                setIsPaused(false);
            };

            synth.current.speak(utterance.current);
            setIsSpeaking(true);
            setIsPaused(false);
        }
    };

    const restartReading = () => {
        synth.current.cancel();
        setIsSpeaking(false);
        setIsPaused(false);  
    };


    const isReadyForExercise = lessonUnderstood && attentionUnderstood;


    return (
        <div>


            <h1>C.O.D: Complément d'Objet Direct</h1>
            <h2>Leçon et explications</h2>
            {selectedMode !== 'deaf' && <div className="mb-3">
                <Button variant="contained" color="primary" onClick={handleReadLesson}>
                    {isSpeaking ? 'Pause' : isPaused ? 'Reprendre la lecture' : 'Ecouter la leçon'}
                </Button>
                {isPaused && (
                    <Button variant="contained" color="secondary" onClick={restartReading} style={{ marginLeft: '10px' }}>
                        Arreter la lecture
                    </Button>
                )}
            </div>}

            <div id="lesson">
                <p>
                    Le complément d'objet direct est un mot ou (groupe de mots) qui se joint au verbe SANS PREPOSITION pour en compléter le sens.
                    Il subit l'action accomplie par le sujet.
                </p>

                <ul>
                    <li>Il appartient au groupe verbal.</li>
                    <li>C'est un complément essentiel, on ne peut pas le supprimer car la phrase n'aurait plus de sens.</li>
                    <li>Le C.O.D est toujours précédé d'un verbe transitif direct.</li>
                    <li>Le C.O.D est le mot qui devient sujet lorsqu'on tourne la phrase à la forme passive.</li>
                </ul>

                <h3>Exemple:</h3>
                <p>    Pierre mange UNE POMME.
                    UNE POMME est mangée par Pierre.
                </p>

                {selectedMode !== "aveugle" && <img alt='' src='http://www.cuisine-de-bebe.com/wp-content/uploads/semaine-%C3%A0-la-pomme.jpg'/>}




                <h2>Comment trouver le complément</h2>
                <p>
                    Pour trouver le C.O.D, il vous suffit de poser la question "QUOI ?" ou "QUI ?"
                </p>

                <h3>Exemple:</h3>
                <p>   Tu conduis une voiture. ➜ Tu conduis quoi ? une voiture.
                </p>
                <p>
                    Le C.O.D est remplaçable par un pronom comme "le", "la", "les", "que"...
                </p>

                <h3>Exemple:</h3>
                <p>  Tu manges la POMME. ➜ Tu LA manges.
                </p>
                <p>
                    "LA" est le pronom qui remplace la pomme, donc "LA" est C.O.D.
                </p>

                <h2>Nature du C.O.D :</h2>
                <p>Le C.O.D peut être de différentes natures. Ci-dessous, une liste accompagnée d'un exemple pour chacune d'entre elles :</p>

                <h3>Un groupe nominal</h3>
                <p>Exemple : Tu conduis la VOITURE. ➜ VOITURE</p>

                <h3>Un pronom</h3>
                <p>Exemple : Tu LA conduis. ➜ LA</p>

                <h3>Un verbe à l'infinitif</h3>
                <p>Exemple : Tu aimes CONDUIRE. ➜ CONDUIRE</p>

                <h3>Une proposition </h3>
                <p>Exemple : Il m'a dit QUE TU ADORAIS CONDUIRE. ➜ QUE TU ADORAIS CONDUIRE.</p>


                {selectedMode !== "aveugle" && <img alt='' src='https://www.stych.fr/img/stych/home/conduite.webp'/>}

            </div>
            <fieldset className='center'>
                <legend>Avez-vous compris la leçon ?</legend>
                <label>
                    <input
                        type="radio"
                        value="Oui"
                        checked={lessonUnderstood}
                        onChange={() => setLessonUnderstood(true)}
                    />
                    Oui
                </label>
                <label>
                    <input
                        type="radio"
                        value="Non"
                        checked={!lessonUnderstood}
                        onChange={() => setLessonUnderstood(false)}
                    />
                    Non
                </label>
            </fieldset>



            <h2>Point d’attention</h2>
            <p> Ne pas confondre C.O.D et Attribut du sujet !<br />
                Tu es COSTAUD ! ➜ "costaud" est attribut du sujet car le C.O.D est toujours différent du sujet.<br />
                Les attributs du sujet sont introduits par des verbes d'états comme sembler, paraître, être...
            </p>

            <fieldset className='center'>
                <legend>Avez-vous compris le point d’attention ?</legend>
                <label>
                    <input
                        type="radio"
                        value="Oui"
                        checked={attentionUnderstood}
                        onChange={() => setAttentionUnderstood(true)}
                    />
                    Oui
                </label>
                <label>
                    <input
                        type="radio"
                        value="Non"
                        checked={!attentionUnderstood}
                        onChange={() => setAttentionUnderstood(false)}
                    />
                    Non
                </label>
            </fieldset>

            <h2>Voir la leçon en vidéo sur YouTube (vidéo proposée par la plateforme École Au Sénégal) </h2>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/Sv4P_sbczGo?si=AQr7mZZxd_XbXiB3" title="YouTube Cours - CM1 - Français : Grammaire - Le Complément d'Objet Direct (C.O.D)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

            <h2>Exercice</h2>

            <CODExercise />



        </div>

    );
};

export default CODLesson;