// src/components/NavMenu.js
import React, { useContext } from 'react';
import { Link, useLocation } from "react-router";
import { UserContext } from '../contexts/UserContext';

const NavMenu = () => {
  const { user, logout } = useContext(UserContext);
  const location = useLocation();

  // Helper function to determine if a link is the current page
  const isCurrentPage = (path) => location.pathname === path;

  return (
    <nav style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', background: '#f8f9fa' }}>
      <div>
        <Link 
          to="/" 
          aria-current={isCurrentPage("/") ? "page" : undefined}
        >
          Accueil
        </Link>
      </div>
      <div>
        {user ? (
          <>
            <button
              onClick={logout}
              style={{
                background: 'none',
                border: 'none',
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
                marginRight: '1rem',
              }}
            >
              Déconnexion
            </button>
          </>
        ) : (
          <>
            <Link 
              to="/connexion" 
              aria-current={isCurrentPage("/connexion") ? "page" : undefined} 
              style={{ marginRight: '1rem' }}
            >
              Connexion
            </Link>
            <Link 
              to="/inscription" 
              aria-current={isCurrentPage("/inscription") ? "page" : undefined}
            >
              Inscription
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default NavMenu;
