import React from 'react';

const Footer = () => {
  return (
    <footer style={{
      textAlign: 'center',
      padding: '1rem',
      background: '#f8f9fa',
      marginTop: 'auto'
    }}>
      © {new Date().getFullYear()} <a target='_blank' href='https://bakhtech.com/'>Bakhtech</a> / <a target='_blank' href='https://oubii.digital/'>Oubii Digital</a>. Tous droits réservés.
    </footer>
  );
};

export default Footer;
