import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate, useParams } from "react-router";
import { TextField, RadioGroup, Radio, FormControlLabel, Button, FormLabel } from '@mui/material';
import config from '../config';

const CreateExercice = () => {
    const { user } = useContext(UserContext);
    const { id_cours, id_lesson } = useParams();
    const [titre, setTitre] = useState('');
    const [questions, setQuestions] = useState([{
        enonce: '',
        type: 'qcm',
        reponses_correctes: [''],
        reponses_incorrectes: ['']
    }]);
    const [error, setError] = useState(null);
    const [formError, setFormError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Ajout d'exercice";
        if (!user) {
            navigate('/connexion'); // Redirect to login if user is not logged in
            return;
        }

        const fetchLessonDetails = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${config.apiBaseUrl}/lessons/${id_lesson}?withCourseDetails=true`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch lesson details');
                }
                const lesson = await response.json();
                if (lesson.id_formateur !== user.id_utilisateur && user.type !== 'admin') {
                    setError('Vous n\'êtes pas autorisé à ajouter un exercice à cette leçon.');
                }
            } catch (err) {
                console.error(err);
                setError('Erreur lors de la vérification des autorisations.');
            }
        };

        fetchLessonDetails();
    }, [user, id_lesson, navigate]);

    const handleQuestionChange = (index, field, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index][field] = value;
        setQuestions(updatedQuestions);
    };

    const handleAnswerChange = (questionIndex, answerType, answerIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex][answerType][answerIndex] = value;
        setQuestions(updatedQuestions);
    };

    const handleAddAnswer = (questionIndex, answerType) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex][answerType].push('');
        setQuestions(updatedQuestions);
    };

    const handleRemoveAnswer = (questionIndex, answerType, answerIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex][answerType].splice(answerIndex, 1);
        setQuestions(updatedQuestions);
    };


    const handleAddQuestion = () => {
        setQuestions([...questions, {
            enonce: '',
            type: 'qcm',
            reponses_correctes: [''],
            reponses_incorrectes: ['']
        }]);
    };

    const handleRemoveQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };

    const setErrorWithScroll = (message) => {
        setFormError(message);
        window.scrollTo(0, 0); // Scroll to top on error
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate and format questions before sending to the backend
        const formattedQuestions = [];

        for (const question of questions) {
            const { enonce, type, reponses_correctes, reponses_incorrectes } = question;

            // Filter out empty answers
            const filteredCorrectAnswers = reponses_correctes.filter(answer => answer.trim() !== '');
            const filteredIncorrectAnswers = reponses_incorrectes.filter(answer => answer.trim() !== '');

            // Validation: Check for empty correct answers
            if (filteredCorrectAnswers.length === 0) {
                setErrorWithScroll(`Toutes les réponses correctes sont vides pour la question : "${enonce}". Veuillez fournir au moins une réponse correcte.`);
                return;
            }

            // Validation: Check for duplicates in correct answers
            const uniqueCorrectAnswers = new Set(filteredCorrectAnswers);
            if (uniqueCorrectAnswers.size !== filteredCorrectAnswers.length) {
                setErrorWithScroll(`Des réponses correctes en double ont été trouvées pour la question : "${enonce}". Veuillez supprimer les doublons.`);
                return;
            }

            // Validation for qcm type questions
            if (type === 'qcm') {
                // Check if all incorrect answers are empty
                if (filteredIncorrectAnswers.length === 0) {
                    setErrorWithScroll(`Toutes les réponses incorrectes sont vides pour la question : "${enonce}". Veuillez fournir au moins une réponse incorrecte.`);
                    return;
                }

                // Check for duplicates in incorrect answers
                const uniqueIncorrectAnswers = new Set(filteredIncorrectAnswers);
                if (uniqueIncorrectAnswers.size !== filteredIncorrectAnswers.length) {
                    setErrorWithScroll(`Des réponses incorrectes en double ont été trouvées pour la question : "${enonce}". Veuillez supprimer les doublons.`);
                    return;
                }

                // Check for duplicates between correct and incorrect answers
                for (const answer of filteredCorrectAnswers) {
                    if (uniqueIncorrectAnswers.has(answer)) {
                        setErrorWithScroll(`La réponse "${answer}" apparaît à la fois dans les réponses correctes et incorrectes pour la question : "${enonce}". Veuillez corriger cela.`);
                        return;
                    }
                }

                // Ensure that possible answers include correct and incorrect ones, then shuffle
                var reponses_possibles = [...filteredCorrectAnswers, ...filteredIncorrectAnswers];
                reponses_possibles = reponses_possibles.sort(() => Math.random() - 0.5);
            } else {
                var reponses_possibles = null;
            }

            // Prepare the formatted question object
            formattedQuestions.push({
                enonce: enonce,
                type: type,
                reponses_correctes: filteredCorrectAnswers,
                reponses_possibles: reponses_possibles
            });
        }

        const requestData = {
            titre,
            questions: formattedQuestions
        };

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${config.apiBaseUrl}/exercices/${id_lesson}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Échec de la création de l\'exercice');
            }

            // Handle success (e.g., navigate to the lesson page or show a success message)
            const data = await response.json();
            console.log('Exercice créé avec succès:', data);
            alert('Exercice créé avec succès')
            navigate(`/cours/${id_cours}/lecon/${id_lesson}`);

        } catch (error) {
            console.error('Erreur lors de la création de l\'exercice:', error);
            // Handle error (e.g., show an error message)
            setErrorWithScroll(error.message);
        }
    };

    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }

    return (
        <div>
            {formError && <div className="alert alert-danger" role="alert">{formError}</div>}
            <div className="container my-5">
                <h1 className="mb-4">Créer un exercice</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <TextField
                            label="Titre de l'exercice"
                            fullWidth
                            value={titre}
                            onChange={(e) => setTitre(e.target.value)}
                            required
                        />
                    </div>

                    {questions.map((question, index) => (
                        <div key={index} className="mb-4">
                            <h5>Question {index + 1}</h5>
                            <div className="mb-3">
                                <TextField
                                    label="Enoncé de la question"
                                    fullWidth
                                    value={question.enonce}
                                    onChange={(e) => handleQuestionChange(index, 'enonce', e.target.value)}
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <FormLabel>Type de question</FormLabel>
                                <RadioGroup
                                    value={question.type}
                                    onChange={(e) => handleQuestionChange(index, 'type', e.target.value)}
                                >
                                    <FormControlLabel value="qcm" control={<Radio />} label="QCM" />
                                    <FormControlLabel value="question ouverte" control={<Radio />} label="Question ouverte" />
                                </RadioGroup>
                            </div>

                            <div className="mb-3">
                                <h6>Réponses correctes</h6>
                                {question.reponses_correctes.map((answer, i) => (
                                    <div key={i} className="mb-3">
                                        <TextField
                                            label={`Réponse correcte ${i + 1}`}
                                            fullWidth
                                            value={answer}
                                            onChange={(e) => handleAnswerChange(index, 'reponses_correctes', i, e.target.value)}
                                            required
                                        />
                                        {question.reponses_correctes.length > 1 && (
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleRemoveAnswer(index, 'reponses_correctes', i)}
                                                className="mt-2"
                                            >
                                                Supprimer cette réponse
                                            </Button>
                                        )}
                                    </div>
                                ))}
                                <Button variant="outlined" onClick={() => handleAddAnswer(index, 'reponses_correctes')}>
                                    Ajouter une réponse correcte
                                </Button>
                            </div>

                            {question.type === 'qcm' && (
                                <div className="mb-3">
                                    <h6>Réponses incorrectes</h6>
                                    {question.reponses_incorrectes.map((answer, i) => (
                                        <div key={i} className="mb-3">
                                            <TextField
                                                label={`Réponse incorrecte ${i + 1}`}
                                                fullWidth
                                                value={answer}
                                                onChange={(e) => handleAnswerChange(index, 'reponses_incorrectes', i, e.target.value)}
                                                required
                                            />
                                            {question.reponses_incorrectes.length > 1 && (
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => handleRemoveAnswer(index, 'reponses_incorrectes', i)}
                                                    className="mt-2"
                                                >
                                                    Supprimer cette réponse
                                                </Button>
                                            )}
                                        </div>
                                    ))}
                                    <Button variant="outlined" onClick={() => handleAddAnswer(index, 'reponses_incorrectes')}>
                                        Ajouter une réponse incorrecte
                                    </Button>
                                </div>
                            )}


                            {questions.length > 1 && <Button variant="contained" color="secondary" onClick={() => handleRemoveQuestion(index)}>
                                Supprimer la question
                            </Button>}
                        </div>
                    ))}

                    <Button variant="outlined" onClick={handleAddQuestion}>
                        Ajouter une question
                    </Button>

                    <div className="mt-4">
                        <Button type="submit" variant="contained" color="primary">
                            Créer l'exercice
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateExercice;