import './App.css';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router';
import Home from './components/Home';
import CODLesson from './components/lessons/CODLesson';
import CourseDetails from './components/CourseDetails';
import Login from './components/Login';
import SignUp from './components/SignUp';
import CreateCourse from './components/CreateCourse';
import LessonDetails from './components/LessonDetails';
import { UserProvider } from './contexts/UserContext';
import CreateLesson from './components/CreateLesson';
import CourseList from './components/CourseList';
import CreateExercice from './components/CreateExercice';
import Layout from './components/Layout';
import Form from './components/Form';
import POC from './components/POC';
import WebComponentsLesson from './components/lessons/WebComponentsLesson';
import MaitriseClavierLesson from './components/lessons/MaitriseClavierLesson';
import { AccessibilityProvider } from './contexts/AccessibilityContext';
import AccessibilitySolutionsCatalog from './components/AccessibilitySolutionsCatalog';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="connexion" element={<Login />} />
      <Route path="inscription" element={<SignUp />} />
      <Route path="cours/:id_cours" element={<CourseDetails />} />
      <Route path="cours/:id_cours/lecon/:id_lesson" element={<LessonDetails />} />
      <Route path="cours/:id_cours/ajouterlecon" element={<CreateLesson />} />
      <Route path="cours/:id_cours/modifierlecon/:id_lesson" element={<CreateLesson />} />
      <Route path="cours/:id_cours/lecon/:id_lesson/ajouterexercice" element={<CreateExercice />} />
      <Route path="CODLesson" element={<CODLesson />} />
      <Route path="mes-cours" element={<CourseList forUser={true} />} />
      <Route path="cours" element={<CourseList />} />
      <Route path="creer-cours" element={<CreateCourse />} />
      <Route path="form" element={<Form />} />
      <Route path="poc" element={<POC />} />
      <Route path="composants-web" element={<WebComponentsLesson />} />
      <Route path="maitrise-clavier" element={<MaitriseClavierLesson />} />
      <Route path="solutions-accessibilite-handicap-auditif" element={<AccessibilitySolutionsCatalog />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <AccessibilityProvider>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </AccessibilityProvider>
    </div>
  );
}

export default App;
