import React, { useEffect, useContext, useState } from 'react';
import { Link } from "react-router";
import config from '../config';


const POC = () => {

    useEffect(() => {
        document.title = "Proof of Concept";
    }, []);

    return (
        <div>
            <p><Link to="/CODLesson">Leçon Complément d'Objet Direct</Link></p>
            <p><Link to="/form">Formulaire</Link></p>
        </div>
    )

}

export default POC;