import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useParams, Link } from "react-router";
import { UserContext } from '../contexts/UserContext';
import { useAccessibility } from '../contexts/AccessibilityContext';
import config from '../config';
import { TextField, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useRef } from 'react';
import { use } from 'react';

function LessonDetails() {
  const { id_cours, id_lesson } = useParams();
  const { user } = useContext(UserContext);
  const { selectedMode } = useAccessibility();
  const [lesson, setLesson] = useState(null);
  const [exercice, setExercice] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);  // Submit button state
  const [openDialog, setOpenDialog] = useState(false);  // Dialog state
  const [results, setResults] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const synth = useRef(window.speechSynthesis);
  const utterance = useRef(null);


  useEffect(() => {
    document.title = "Détails de la leçon";

    const fetchLessonDetails = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${config.apiBaseUrl}/lessons/${id_lesson}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch lesson details');
        }
        const data = await response.json();
        if (data?.titre) document.title = data.titre;
        setLesson(data);

        // Fetch the exercise associated with the lesson
        const exerciceResponse = await fetch(`${config.apiBaseUrl}/exercices?id_lesson=${id_lesson}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!exerciceResponse.ok) {
          throw new Error('Failed to fetch exercise details');
        }
        const exerciceData = await exerciceResponse.json();
        setExercice(exerciceData.length > 0 ? exerciceData[0] : null);  // Assuming only one exercise per lesson
      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLessonDetails();
  }, [id_lesson]);

  useEffect(() => {
    return () => {
      synth.current.cancel(); // Stop speech synthesis on unmount
    };
  }, []);

  const handleInputChange = (questionId, value) => {
    setUserAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };

  const handleSubmit = async () => {
    // Check if any question is unanswered
    const unansweredQuestions = exercice.questions.filter(q => !userAnswers[q.id_question]);

    if (unansweredQuestions.length > 0) {
      setOpenDialog(true);
      return;
    }

    await proceedWithSubmission(); // Proceed without dialog
  };



  const proceedWithSubmission = async () => {
    setSubmitDisabled(true); // Disable submit button
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${config.apiBaseUrl}/exercices/grade/${exercice.id_exercice}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ answers: userAnswers })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error details:', errorText);
        throw new Error('Failed to grade exercise');
      }

      const resultData = await response.json();
      setResults(resultData);

    } catch (err) {
      console.error('Error during grading:', err);
      setError(err.message);
      setSubmitDisabled(false); // Re-enable submit button on error
    }
  };

  const handleDialogClose = async (proceed) => {
    setOpenDialog(false);
    if (proceed) {
      await proceedWithSubmission();
    }
  };

  const handleReadLesson = () => {
    const lessonDiv = document.getElementById("lesson")
    if (!lessonDiv.textContent) return;

    if (isSpeaking) {
      synth.current.pause();
      setIsSpeaking(false);
      setIsPaused(true);
    } else if (isPaused) {
      synth.current.resume();
      setIsSpeaking(true);
      setIsPaused(false);
    } else {
      utterance.current = new SpeechSynthesisUtterance(lessonDiv.textContent); // Remove HTML tags
      utterance.current.lang = 'fr-FR';
      utterance.current.onend = () => {
        setIsSpeaking(false);
        setIsPaused(false);
      };

      synth.current.speak(utterance.current);
      setIsSpeaking(true);
      setIsPaused(false);
    }
  };

  const restartReading = () => {
    synth.current.cancel();
    setIsSpeaking(false);
    setIsPaused(false);
    //handleReadLesson();
  };

  console.log("Selected Accessibility Mode:", selectedMode);

  // Process lesson content only when necessary
  const blindLessonContent = useMemo(() => {
    if (!lesson?.contenu) return "";

    return lesson.contenu
      .replace(/<img\s+[^>]*alt=["']\s*["'][^>]*>/gi, "") // Remove empty alt images
      .replace(/<img\s+[^>]*alt=["']([^"']+)["'][^>]*>/gi, '<p>Image: $1</p>'); // Replace valid alt images
  }, [lesson?.contenu]);

  if (loading) return <div className="text-center mt-5"><div className="spinner-border text-primary" role="status"><span className="visually-hidden">Chargement...</span></div></div>;
  if (error) return <div className="alert alert-danger" role="alert">Error: {error}</div>;

  return (


    <div className="container my-5">
      <p><Link to={`/cours/${id_cours}`} >
        Retour au cours
      </Link></p>
      {user && (user.id_utilisateur === lesson.id_formateur || user.type === "admin") && (
        <Link to={`/cours/${id_cours}/modifierlecon/${id_lesson}`} className="btn btn-primary mb-3">
          Modifier Leçon
        </Link>
      )}
      <h1 className="mb-4">{lesson?.titre}</h1>
      {selectedMode !== 'deaf' && <div className="mb-3">
        <Button variant="contained" color="primary" onClick={handleReadLesson}>
          {isSpeaking ? 'Pause' : isPaused ? 'Reprendre la lecture' : 'Ecouter la leçon'}
        </Button>
        {isPaused && (
          <Button variant="contained" color="secondary" onClick={restartReading} style={{ marginLeft: '10px' }}>
            Arreter la lecture
          </Button>
        )}
      </div>}
      {lesson?.contenu && (
        <div id="lesson"
          dangerouslySetInnerHTML={{ __html: selectedMode === "aveugle" ? blindLessonContent : lesson.contenu }}
        />
      )}

      {/* Display exercise if it exists */}
      {exercice && (
        <div>
          <h2>{exercice.titre}</h2>
          {exercice.questions.map((question, index) => (
            <div key={question.id_question} className="mb-3">
              <h5>Question {index + 1}</h5>
              <p>{question.enonce}</p>

              {question.type === 'qcm' && (
                <FormControl component="fieldset" fullWidth required>
                  <FormLabel component="legend">Sélectionnez la ou les réponse(s) correcte(s).</FormLabel>
                  <FormGroup>
                    {question.reponses_possibles.map((reponse, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              const selected = userAnswers[question.id_question] || [];
                              if (e.target.checked) {
                                handleInputChange(question.id_question, [...selected, reponse]);
                              } else {
                                handleInputChange(question.id_question, selected.filter(ans => ans !== reponse));
                              }
                            }}
                          />
                        }
                        label={reponse}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}

              {question.type === 'question ouverte' && (
                <TextField
                  label="Votre réponse"
                  fullWidth
                  variant="outlined"
                  placeholder="Votre réponse..."
                  onChange={(e) => handleInputChange(question.id_question, e.target.value)}
                />
              )}

              {/* Display the user's answer and the correct answer(s) if the results are available */}
              {results && (
                <div className="mt-2">
                  <strong>
                    {Array.isArray(userAnswers[question.id_question]) && userAnswers[question.id_question].length > 1
                      ? "Vos réponses: "
                      : "Votre réponse: "}
                  </strong>
                  <span>
                    {Array.isArray(userAnswers[question.id_question])
                      ? userAnswers[question.id_question].map(answer => `"${answer}"`).join(", ")
                      : `"${userAnswers[question.id_question]}"`
                    }.
                  </span>
                  <br />
                  <strong>
                    {results.exercice.questions.find(q => q.id_question === question.id_question).reponses_correctes.length > 1
                      ? "Réponse(s) correcte(s): "
                      : "Réponse correcte: "}
                  </strong>
                  <span>
                    {results.exercice.questions.find(q => q.id_question === question.id_question).reponses_correctes.map(answer => `"${answer}"`).join(", ")}.
                  </span>
                </div>
              )}
            </div>
          ))}
          <Button variant="contained" color="success" className="mt-3" onClick={handleSubmit} disabled={submitDisabled}>Valider</Button>
          {results && (
            <div className="alert alert-info mt-3">
              <strong>Note finale:</strong> {results.grade}/100
            </div>
          )}
        </div>
      )}

      {/* Show "Ajouter un exercice à cette leçon" link only if no exercise exists */}
      {!exercice && user && (user.id_utilisateur === lesson.id_formateur || user.type === "admin") &&
        <Link to={`/cours/${id_cours}/lecon/${id_lesson}/ajouterexercice`} className="btn btn-primary mb-3">Ajouter un exercice à cette leçon</Link>
      }

      {/* Dialog to warn about unanswered questions */}
      <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Questions non répondues</DialogTitle>
        <DialogContent>
          Certaines questions n'ont pas été répondues. Voulez-vous continuer malgré tout?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="secondary" autoFocus>
            Continuer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LessonDetails;
